import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs';
import { resetAppState } from '../../../../store/helper-fns/reset-app-state';
import { AppState } from '../../../../store/state/app.state';
import { UnitService } from '../../../crash-report/services/api/unit/unit.service';
import { VersionService } from '../../../crash-report/services/api/version/version.service';
import { UnitActions } from '../../../crash-report/store/actions';
import { ServiceLocationSelectors, UnitSelectors } from '../../../crash-report/store/selectors';
import { SnackbarService } from '../../../shared/modules/snackbar/services/snackbar/snackbar.service';
import { ConfigService } from '../../../shared/services/config/config.service';
import { LocalStorageService } from '../../../shared/services/local-storage/local-storage.service';
import { ServerErrorService } from '../../../shared/services/server-error/server-error.service';
import { SessionStorageService } from '../../../shared/services/session-storage/session-storage.service';
import { OssOptional } from '../../../shared/utils/oss-optional';
import { ApiCurrentUser } from '../../data-model/models/api-current-user.model';
import { ClientCurrentUser } from '../../data-model/models/client-current-user.model';
import { AuthService } from '../../services/api/auth/auth.service';
import { AuthActions } from '../actions';
import { AuthSelectors } from '../selectors';

@Injectable()
export class AuthEffects {
  $signIn = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signIn),
      switchMap(action =>
        this.authService.signIn(action.username, action.password).pipe(
          map(currentUser =>
            AuthActions.signedIn({
              currentUser: this.mapCurrentUser(currentUser),
              apiToken: currentUser['oss-api-token'],
              username: action.username,
              password: action.password,
            })
          ),
          tap(action => {
            this.localStorageService.setApiToken(action.apiToken);
            this.localStorageService.setUser(action.currentUser);
            this.localStorageService.clearUnit();
            this.localStorageService.clearAssignmentId();
            this.store.dispatch(UnitActions.unitAssigned({ unit: null, assignmentId: null }));
          }),
          catchError(error => this.serverErrorService.handleError(error))
        )
      )
    );
  });

  $signedIn = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signedIn),
      switchMap(action =>
        this.versionService.getCurrentVersion().pipe(
          filter(response => {
            if (response.clientVersion.client_version !== this.configService.getVersion()) {
              this.localStorageService.clearApiToken();
              this.localStorageService.clearUser();
              this.sessionStorageService.setLoginCredentials({ username: action.username, password: action.password });
              console.info('client version has been updated');
              alert('A new version of the application is available. Click OK to refresh the page and update the application.');
              window.location.href = `${window.location.origin}${window.location.pathname}?refresh=${new Date().getTime()}`;
              return false;
            }
            return true;
          }),
          map(() => AuthActions.clientVersionValidated()),
          catchError(error => this.serverErrorService.handleError(error))
        )
      )
    );
  });

  signOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signOut),
      withLatestFrom(
        this.store.select(UnitSelectors.selectAssignmentId),
        this.store.select(ServiceLocationSelectors.selectServiceLocationId),
        this.store.select(AuthSelectors.selectUserIsReviewer),
        this.store.select(AuthSelectors.selectUserIsAdministrator),
        this.store.select(AuthSelectors.selectUserIsAdminUser)
      ),
      switchMap(([, assignmentId, serviceLocationId, isReviewer, isAdministrator, isAdminUser]) => {
        resetAppState(this.store, false);

        // signOut$ will log the user out, clear local storage, clear state, and redirect to the sign in page
        const signOut$ = this.authService.signOut().pipe(
          tap(() => {
            this.localStorageService.clearApiToken();
            this.localStorageService.clearUser();
            this.localStorageService.clearServiceLocation();
          }),
          map(() => AuthActions.signedOut()),
          tap(() => this.router.navigate(['/auth/sign-in'])),
          tap(() => this.snackbarService.showSnackbar('You have successfully signed out.', 'info', 1000)),
          catchError(error => this.serverErrorService.handleError(error))
        );

        // if the user is not assigned to the New Orleans service location or is a reviewer, admin user, or administrator, just log the user out ...
        if (![this.NEW_ORLEANS_SERVICE_LOCATION_ID].includes(serviceLocationId) || isReviewer || isAdministrator || isAdminUser) {
          return signOut$;
        }

        // if the user is assigned to the New Orleans service location, unassign the unit, clear local storage, clear state, log the user out ...
        return this.unitService.unassignUnit(assignmentId).pipe(
          tap(() => {
            this.localStorageService.clearUnit();
            this.localStorageService.clearAssignmentId();
            this.store.dispatch(UnitActions.unitAssigned({ unit: null, assignmentId: null }));
          }),
          switchMap(() => signOut$),
          catchError(() => signOut$)
        );
      })
    );
  });

  forgotPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.forgotPassword),
      switchMap(action => {
        return this.authService.forgotPassword(action.username, action.email).pipe(
          map(response => {
            if (response.success) {
              this.snackbarService.showSnackbar('A password reset link has been emailed to you.', 'info');
              this.router.navigate(['../']);
            } else {
              this.snackbarService.showSnackbar('Your request to reset your password was unsuccesful.', 'error');
            }
            return AuthActions.forgotPasswordSuccessful();
          }),
          catchError(error => this.serverErrorService.handleError(error))
        );
      })
    );
  });

  resetPassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.resetPassword),
      switchMap(action => {
        return this.authService.resetPassword(action.password, action.token).pipe(
          map(() => AuthActions.resetPasswordSuccessful()),
          tap(() => this.router.navigate(['../'])),
          tap(() => this.snackbarService.showSnackbar('Your password has successfully been reset.', 'info')),
          catchError(error => this.serverErrorService.handleError(error))
        );
      })
    );
  });

  signUp$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signUp),
      switchMap(action => {
        return this.authService.signUp(action.request).pipe(
          map(() => AuthActions.signUpSuccessful()),
          tap(() => this.router.navigate(['../'])),
          tap(() => this.snackbarService.showSnackbar('Your account has been created.', 'info')),
          catchError(error => this.serverErrorService.handleError(error))
        );
      })
    );
  });

  private mapCurrentUser(currentUser: ApiCurrentUser): ClientCurrentUser {
    return {
      carrier: OssOptional.ofNullable(currentUser.carrier).orElseNull(),
      email: currentUser.email,
      first_name: currentUser.first,
      is_admin: OssOptional.ofNullable(currentUser.is_admin).orElse(false),
      is_carrier: OssOptional.ofNullable(currentUser.is_carrier).orElse(false),
      last_name: currentUser.last,
      roles: currentUser.roles,
      service_location_ids: currentUser.serviceLocationIds,
      username: currentUser.username,
    };
  }

  private readonly NEW_ORLEANS_SERVICE_LOCATION_ID = 1;

  constructor(
    private readonly actions$: Actions,
    private readonly authService: AuthService,
    private readonly router: Router,
    private readonly snackbarService: SnackbarService,
    private readonly serverErrorService: ServerErrorService,
    private readonly localStorageService: LocalStorageService,
    private readonly unitService: UnitService,
    private readonly store: Store<AppState>,
    private readonly configService: ConfigService,
    private readonly sessionStorageService: SessionStorageService,
    private readonly versionService: VersionService
  ) {}
}
