import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiOperationStatus } from '../../../../shared/data-model/models/api-operation-status.model';
import { UnsupportedOperationException } from '../../../../shared/data-model/models/unsupported-operation-exception.model';
import { ApiGatewayService } from '../../../../shared/services/api-gateway/api-gateway.service';
import { StringUtils } from '../../../../shared/utils/string-utils';
import { SupervisorCrashReportSummaryResponse } from '../../../data-model/models/supevisor-crash-report-summary-response.model';
import { CrashReportSummaryListTab } from '../../../data-model/types/crash-report-summary-list-tab.type';

@Injectable({ providedIn: 'root' })
export class SupervisorService {
  private readonly BASE_URL = 'oss/supervisor';

  constructor(private readonly apiGateway: ApiGatewayService) {}

  getSupervisorCrashReportSummaries(
    serviceLocationId: number,
    tab: Omit<CrashReportSummaryListTab, 'inProgress' | 'submitted' | 'myApproved' | 'myRejected'>,
    limit: number = 1000,
    offset: number = 0
  ): Observable<SupervisorCrashReportSummaryResponse> {
    let statuses: string[];
    let hydrateReviews = false;
    let hydrateECrashSubmissionStatus = false;

    switch (tab) {
      case 'needsReview':
        statuses = ['agent_qc_check'];
        hydrateReviews = true;
        break;
      case 'rejected':
        statuses = ['incomplete'];
        hydrateReviews = true;
        break;
      case 'approved':
        statuses = ['secondary_qc_check', 'secondary_incomplete', 'ready', 'submitted'];
        hydrateECrashSubmissionStatus = true;
        break;
      default:
        throw new UnsupportedOperationException(`Unsupported tab: ${tab}`);
    }

    const additionalHeaders = { ETag: StringUtils.EMPTY };

    return this.apiGateway.get(
      `${this.BASE_URL}/reports`,
      null,
      true,
      additionalHeaders,
      this.buildUrlSearchParams(serviceLocationId, limit, offset, statuses, hydrateReviews, hydrateECrashSubmissionStatus)
    );
  }

  approveCrashReport(crashReportNumber: string, serviceLocationId: number): Observable<ApiOperationStatus> {
    return this.apiGateway.post(`${this.BASE_URL}/review`, { crash_num: crashReportNumber, is_accepted: true, service_location_id: serviceLocationId });
  }

  rejectCrashReport(crashReportNumber: string, notes: string, serviceLocationId: number): Observable<ApiOperationStatus> {
    return this.apiGateway.post(`${this.BASE_URL}/review`, {
      crash_num: crashReportNumber,
      is_accepted: false,
      notes: notes,
      status_change_notes: notes,
      service_location_id: serviceLocationId,
    });
  }

  private buildUrlSearchParams(
    serviceLocationId: number,
    limit: number,
    offset: number,
    statuses: string[],
    hydrateReviews: boolean,
    hydrateECrashSubmissionStatus: boolean
  ): URLSearchParams {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.append('service_location_id', serviceLocationId.toString());
    urlSearchParams.append('limit', limit.toString());
    urlSearchParams.append('offset', offset.toString());
    urlSearchParams.append('hydrate_reviews', hydrateReviews.toString());
    urlSearchParams.append('hydrate_ecrash_submission_status', hydrateECrashSubmissionStatus.toString());

    statuses.forEach(status => urlSearchParams.append('status', status));

    return urlSearchParams;
  }
}
