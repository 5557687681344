import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, finalize, map, switchMap, tap, withLatestFrom } from 'rxjs';
import { AppState } from '../../../../store/state/app.state';
import { AuthSelectors } from '../../../auth/store/selectors';
import { PosthogService } from '../../../shared/services/posthog/posthog.service';
import { ServerErrorService } from '../../../shared/services/server-error/server-error.service';
import { UuidService } from '../../../shared/services/uuid/uuid.service';
import { LoadingActions } from '../../../shared/store/actions';
import { ReviewerService } from '../../services/api/reviewer/reviewer.service';
import { SupervisorService } from '../../services/api/supervisor/supervisor.service';
import { CrashReportApprovalActions, NavigationActions } from '../actions';
import { CrashReportSelectors, ServiceLocationSelectors } from '../selectors';

@Injectable()
export class CrashReportApprovalEffects {
  approveCrashReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportApprovalActions.approveCrashReport),
      withLatestFrom(
        this.store.select(CrashReportSelectors.selectSupervisorCrashReportSummary),
        this.store.select(AuthSelectors.selectUserIsReviewer),
        this.store.select(ServiceLocationSelectors.selectServiceLocationId),
        this.store.select(AuthSelectors.selectUser)
      ),
      switchMap(([, needsReviewCrashReportSummary, isReviewer, serviceLocationId, user]) => {
        const loaderId = this.uuidService.generate();
        this.store.dispatch(LoadingActions.showLoadingIndicatorWithId({ message: 'Approving crash report...', loaderId }));
        const approveCrashReport$ = isReviewer
          ? this.reviewerService.approveCrashReport(needsReviewCrashReportSummary.oss_id, serviceLocationId)
          : this.supervisorService.approveCrashReport(needsReviewCrashReportSummary.oss_id, serviceLocationId);
        return approveCrashReport$.pipe(
          tap(() => this.posthogService.captureReportApprovedEvent(needsReviewCrashReportSummary.oss_id, user.username, serviceLocationId)),
          map(() => CrashReportApprovalActions.crashReportApproved()),
          catchError(error => this.serverErrorService.handleError(error)),
          finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicatorWithId({ loaderId: loaderId })))
        );
      })
    );
  });

  rejectCrashReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportApprovalActions.rejectCrashReport),
      withLatestFrom(
        this.store.select(CrashReportSelectors.selectSupervisorCrashReportSummary),
        this.store.select(AuthSelectors.selectUserIsReviewer),
        this.store.select(ServiceLocationSelectors.selectServiceLocationId),
        this.store.select(AuthSelectors.selectUser)
      ),
      switchMap(([action, needsReviewCrashReportSummary, isReviewer, serviceLocationId, user]) => {
        const loaderId = this.uuidService.generate();
        this.store.dispatch(LoadingActions.showLoadingIndicatorWithId({ message: 'Rejecting crash report...', loaderId }));
        const rejectCrashReport$ = isReviewer
          ? this.reviewerService.rejectCrashReport(needsReviewCrashReportSummary.oss_id, action.comment, serviceLocationId)
          : this.supervisorService.rejectCrashReport(needsReviewCrashReportSummary.oss_id, action.comment, serviceLocationId);
        return rejectCrashReport$.pipe(
          tap(() => {
            this.posthogService.captureReportRejectedEvent(needsReviewCrashReportSummary.oss_id, user.username, serviceLocationId);
            this.store.dispatch(NavigationActions.navigateFromReviewFormToReviewRejectedApprovedList());
          }),
          map(() => CrashReportApprovalActions.crashReportRejected()),
          catchError(error => this.serverErrorService.handleError(error)),
          finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicatorWithId({ loaderId })))
        );
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly serverErrorService: ServerErrorService,
    private readonly supervisorService: SupervisorService,
    private readonly reviewerService: ReviewerService,
    private readonly uuidService: UuidService,
    private readonly posthogService: PosthogService
  ) {}
}
