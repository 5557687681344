export const environment = {
  production: false,
  name: 'development',
  appConfig: {
    apiUrl: 'https://dev-api.onsceneservices.com',
    apiVersion: "1.0.0",
    easyStreetDrawLicenseId : "65716463",
    easyStreetDrawServiceUrl : "https://esd.onsceneservices.com",
    googleMapsApiMapId: "cb761d3874009473759",
    plateToVinActive: false,
    plateToVinApiKey: "GTbQzrIJWhKwB0hz15HlIBPPGMuBw31D",
    posthogApiKey:"phc_bcngdTPZOnRg9X0c5LlVJ1yw1MB8BfysoPZMoZKMvaF",
    radarActive: true,
    radarApiKey: 'prj_live_pk_2fc44fecf83d139209ebcefcad8e188a3b3aaae0',
    smartyStreetsActive: false,
    smartyStreetsApiKey: "197556638065773931",
    version: "2.0.11",
  },
};