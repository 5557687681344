import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import { Observable, of } from 'rxjs';
import { getDummyAction } from '../../../../store/helper-fns/get-dummy-action';
import { SnackbarActions } from '../../modules/snackbar/store/actions';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({ providedIn: 'root' })
export class ServerErrorService {
  private readonly ACCEPTED = 202;
  private readonly BAD_REQUEST = 400;
  private readonly FORBIDDEN = 403;
  private readonly SERVICE_UNAVAILABLE = 503;
  private readonly UNAUTHORIZED = 401;

  // every n milliseconds, a request is made to https://www.gstatic.com/generate_204 to check connectivity
  // if the request fails, the status code is 0
  private readonly CONENCTVITY_CHECK_FAILED = 0;

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router
  ) {}

  handleError(error: HttpErrorResponse): Observable<Action> {
    // if the request was handled by the service worker or the connectivity check failed, return a dummy action
    if (error.status === this.ACCEPTED || error.status === this.CONENCTVITY_CHECK_FAILED) {
      return of(getDummyAction());
    }

    // if the user is unauthorized or forbidden, clear local storage and redirect to sign in
    if (error.status === this.UNAUTHORIZED || error.status === this.FORBIDDEN) {
      this.localStorageService.clearApiToken();
      this.localStorageService.clearUser();
      this.localStorageService.clearServiceLocation();
      this.localStorageService.clearUnit();
      this.localStorageService.clearAssignmentId();

      this.router.navigate(['/auth/sign-in']);
      return of(SnackbarActions.showSnackbar({ message: 'Unauthorized. Please sign in again.', level: 'error' }));
    }

    // if the network is unavailable and the request was not handled by the service worker, show an offline message
    if (error.status === this.SERVICE_UNAVAILABLE) {
      return of(SnackbarActions.showSnackbar({ message: 'App is currently offline. Unable to complete request.', level: 'info' }));
    }

    // if the request was bad, show a snackbar with the error message
    if (error.status === this.BAD_REQUEST) {
      if (error.error['message'] === 'duplicate item num') {
        return of(SnackbarActions.showSnackbar({ message: 'Item number is already in use.', level: 'error' }));
      }
      return of(SnackbarActions.showSnackbar({ message: error.error['display_message'], level: 'error' }));
    }

    Sentry.captureException(error);
    return of(SnackbarActions.showSnackbar({ message: 'Unspecified server error.', level: 'error' }));
  }
}
