/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, Component, ErrorHandler, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import Radar from 'radar-sdk-js';
import { Observable } from 'rxjs';
import packageInfo from '../../package.json';
import { environment } from '../environments/environment';
import { TechnicalException } from './modules/shared/data-model/models/technical-exception.model';
import { SnackbarService } from './modules/shared/modules/snackbar/services/snackbar/snackbar.service';
import { SentryErrorHandler } from './modules/shared/sentry-error-handler';
import { ConfigService } from './modules/shared/services/config/config.service';
import { NetworkStatusService } from './modules/shared/services/network-status/network-status.service';
import { SmartyStreetsService } from './modules/shared/services/smarty-streets/smarty-streets.service';
import { LoadingSelectors } from './modules/shared/store/selectors';
import { CommonUtils } from './modules/shared/utils/common-utils';
import { AppState } from './store/state/app.state';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MatProgressSpinnerModule, CommonModule],
  templateUrl: './app.component.html',
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true },
  ],
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  isLoading$: Observable<boolean>;
  loadingMessage$: Observable<string | null>;

  // inject the network status service to ensure it's included in the bundle
  constructor(
    private readonly networkStatusService: NetworkStatusService,
    private readonly store: Store<AppState>,
    private readonly snackbarService: SnackbarService,
    private readonly configService: ConfigService,
    private readonly smartyStreetsService: SmartyStreetsService
  ) {
    navigator.serviceWorker.register('./custom-sw.js').then(registration => {
      registration.addEventListener('updatefound', () => {
        const newWorker = registration.installing;
        newWorker.addEventListener('statechange', () => {
          if (newWorker.state === 'installed') {
            if (navigator.serviceWorker.controller) {
              alert('A new version of the application is available. Click OK to refresh the page and update the application.');
              window.location.href = `${window.location.origin}${window.location.pathname}?refresh=${new Date().getTime()}`;
            }
          }
        });
      });
    });
  }

  ngOnInit(): void {
    this.configureSentry();
    this.configureAddressAutocompleteService();

    this.isLoading$ = this.store.select(LoadingSelectors.selectShowLoadingIndicator);
    this.loadingMessage$ = this.store.select(LoadingSelectors.selectLoadingMessage);

    if (CommonUtils.isNullOrUndefined(navigator.serviceWorker)) {
      this.snackbarService.showSnackbar('Offline mode is not supported.', 'info');
    }
  }

  private configureAddressAutocompleteService(): void {
    switch (true) {
      case !this.configService.getSmartyStreetsActive() && !this.configService.getRadarActive(): {
        break;
      }
      case this.configService.getSmartyStreetsActive() && !this.configService.getRadarActive(): {
        this.configureSmartyStreets();
        break;
      }
      case !this.configService.getSmartyStreetsActive() && this.configService.getRadarActive(): {
        this.configureRadar();
        break;
      }
      default: {
        throw new TechnicalException('Both SmartyStreets and Radar are active. Only one can be active at a time.');
      }
    }
  }

  private configureRadar(): void {
    Radar.initialize(this.configService.getRadarApiKey());
  }

  private configureSmartyStreets(): void {
    this.smartyStreetsService.configure();
  }

  private configureSentry(): void {
    if (!environment.production) {
      return;
    }

    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // result in 25% of transactions being profiled (0.5 * 0.5 = 0.25)
    const profilesSampleRate = 1.0;

    Sentry.init({
      dsn: 'https://4d6fb42a1f1ef2867f1d94f840e32d2b@o4506400343851008.ingest.sentry.io/4506418430410752',
      release: `accident-reporting-app@${packageInfo.version}`,
      environment: environment.name.toLowerCase(),
      integrations: [
        new Sentry.BrowserTracing({ tracePropagationTargets: [environment.appConfig.apiUrl] }),
        Sentry.browserTracingIntegration(),
        Sentry.metrics.metricsAggregatorIntegration(),
        Sentry.browserTracingIntegration({ enableInp: true }),
        new Sentry.BrowserProfilingIntegration(),
        new Sentry.Replay(),
        new Sentry.Feedback({
          autoInject: false,
          showBranding: false,
          showEmail: false,
          isNameRequired: true,
          buttonLabel: 'Share Feedback',
          submitButtonLabel: 'Send Feedback',
          formTitle: 'Share Feedback',
          messagePlaceholder: 'Please share your feedback.',
          successMessageText: 'Thank you for your feedback!',
        }),
      ],
      autoSessionTracking: true,
      routingInstrumentation: Sentry.routingInstrumentation,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 0.1,
      tracesSampleRate: 0.1,
      tracePropagationTargets: ['localhost', /^https:\/\/api\.onsceneservices\.com/, /^https:\/\/dev-api\.onsceneservices\.com/],
      profilesSampleRate,
    } as any);
  }
}
