import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { PrimaryButtonComponent } from '../../../../shared/components/buttons/primary-button/primary-button.component';
import { OssInputComponent } from '../../../../shared/components/form-controls/oss-input/oss-input.component';
import { UnsupportedOperationException } from '../../../../shared/data-model/models/unsupported-operation-exception.model';
import { IsOfflineDirective } from '../../../../shared/directives/is-offline/is-offline.directive';
import { CommonUtils } from '../../../../shared/utils/common-utils';
import { OssActivityRecord } from '../../../data-model/models/oss-activity-record.model';
import { ActivityTableCommentsModalComponent } from '../activity-table-comments-modal/activity-table-comments-modal.component';

@Component({
  selector: 'app-activity-table-revision-modal',
  standalone: true,
  imports: [PrimaryButtonComponent, CommonModule, OssInputComponent, ReactiveFormsModule, MatIconModule, IsOfflineDirective],
  templateUrl: './activity-table-revision-modal.component.html',
  styleUrl: './activity-table-revision-modal.component.scss',
})
export class ActivityTableRevisionModalComponent implements OnInit {
  form: FormGroup;

  constructor(
    private dialogRef: MatDialogRef<ActivityTableCommentsModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { activityRecord: OssActivityRecord; isInitialVerification: boolean; serviceLocationId: number },
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      itemNumber: [this.data.activityRecord.item_num, [this.getItemNumberValdidatorFunction(this.data.serviceLocationId)]],
    });
  }

  onReverify(): void {
    this.dialogRef.close({ success: true, itemNumber: this.form.value.itemNumber });
  }

  onClose(): void {
    this.dialogRef.close({ success: false });
  }

  private getItemNumberValdidatorFunction(serviceLocationId: number): ValidatorFn {
    const newOrleansServiceLocationId = 1;
    const newOrleansItemNumberRegex = /^[A-Z]-\d{5}-\d{2}$/;

    return (control: FormControl): ValidationErrors | null => {
      const itemNumber = control.value;
      if (CommonUtils.isNullOrUndefinedOrEmptyString(itemNumber)) {
        return null;
      }
      switch (serviceLocationId) {
        case newOrleansServiceLocationId: {
          return newOrleansItemNumberRegex.test(itemNumber) ? null : { invalidItemNumber: true };
        }
        default: {
          throw new UnsupportedOperationException(`Service location id ${serviceLocationId} is not supported`);
        }
      }
    };
  }
}
