import { Directive, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { NetworkStatusService } from '../../services/network-status/network-status.service';
import { CommonUtils } from '../../utils/common-utils';

@Directive({ selector: '[appIsOffline]', standalone: true })
export class IsOfflineDirective implements OnInit, OnDestroy {
  @Input({ required: false }) offlineText: string = 'You cannot perform this action while offline';
  @Input({ required: false }) offlineTextPosition: 'top' | 'bottom' | 'left' | 'right' = 'top';

  @Output() offlineClick = new EventEmitter<MouseEvent>();

  private onDestroySubject = new Subject<void>();
  private styles: Record<string, string>;
  private tooltip: HTMLElement | null = null;

  constructor(
    private elementRef: ElementRef,
    private networkStatusService: NetworkStatusService,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.styles = {
      background: 'rgba(0, 0, 0, 0.75)',
      borderRadius: '4px',
      color: '#fff',
      fontSize: '14px',
      padding: '8px 16px',
      position: 'absolute',
      textAlign: 'center',
      transform: 'translate(-50%, -100%)',
      zIndex: '1000',
    };
    this.networkStatusService.networkStatus$.pipe(takeUntil(this.onDestroySubject)).subscribe(() => this.updateElementState());
  }

  ngOnDestroy(): void {
    this.removeTooltip();
    this.onDestroySubject.next();
    this.onDestroySubject.complete();
    this.onDestroySubject = null;
  }

  @HostListener('mouseenter')
  onMouseEnter(): void {
    if (this.networkStatusService.isOffline) {
      this.addTooltip();
    }
  }

  @HostListener('mouseleave')
  onMouseLeave(): void {
    this.removeTooltip();
  }

  @HostListener('click', ['$event'])
  onClick(event: MouseEvent): void {
    if (this.networkStatusService.isOnline) {
      this.offlineClick.emit(event);
      return;
    }
    event.stopImmediatePropagation();
    event.preventDefault();
  }

  private updateElementState(): void {
    this.renderer.setStyle(this.elementRef.nativeElement, 'opacity', this.networkStatusService.isOnline ? '1.0' : '0.5');
  }

  private addTooltip(): void {
    if (CommonUtils.isNullOrUndefined(this.tooltip)) {
      this.tooltip = this.renderer.createElement('div');

      const text = this.renderer.createText(this.offlineText);
      this.renderer.appendChild(this.tooltip, text);

      Object.entries(this.styles).forEach(([key, value]) => this.renderer.setStyle(this.tooltip, key, value));

      const hostRect = this.elementRef.nativeElement.getBoundingClientRect();
      switch (this.offlineTextPosition) {
        case 'top':
          this.renderer.setStyle(this.tooltip, 'top', `${hostRect.top}px`);
          this.renderer.setStyle(this.tooltip, 'left', `${hostRect.left + hostRect.width / 2}px`);
          this.renderer.setStyle(this.tooltip, 'transform', 'translate(-50%, -100%)');
          break;

        case 'bottom':
          this.renderer.setStyle(this.tooltip, 'top', `${hostRect.bottom}px`);
          this.renderer.setStyle(this.tooltip, 'left', `${hostRect.left + hostRect.width / 2}px`);
          this.renderer.setStyle(this.tooltip, 'transform', 'translate(-50%, 0)');
          break;

        case 'left':
          this.renderer.setStyle(this.tooltip, 'top', `${hostRect.top + hostRect.height / 2}px`);
          this.renderer.setStyle(this.tooltip, 'left', `${hostRect.left}px`);
          this.renderer.setStyle(this.tooltip, 'transform', 'translate(-100%, -50%)');
          break;

        case 'right':
          this.renderer.setStyle(this.tooltip, 'top', `${hostRect.top + hostRect.height / 2}px`);
          this.renderer.setStyle(this.tooltip, 'left', `${hostRect.right}px`);
          this.renderer.setStyle(this.tooltip, 'transform', 'translate(0, -50%)');
          break;
      }

      this.renderer.appendChild(document.body, this.tooltip);
    }
  }

  private removeTooltip(): void {
    if (CommonUtils.isDefined(this.tooltip)) {
      this.renderer.removeChild(document.body, this.tooltip);
      this.tooltip = null;
    }
  }
}
