import { Injectable } from '@angular/core';
import posthog, { Properties } from 'posthog-js';

@Injectable({ providedIn: 'root' })
export class PosthogService {
  captureReportSubmittedEvent(ossId: string, reportWritten: boolean, agent: string, serviceLocationId: number): void {
    const properties: Properties = { start_time: Date.now(), oss_id: ossId, report_written: reportWritten, agent, service_location_id: serviceLocationId };
    this.captureEvent('Report Submitted', properties);
  }

  captureItemCreatedEvent(ossId: string, agent: string, serviceLocationId: number): void {
    const properties: Properties = { start_time: Date.now(), oss_id: ossId, agent, service_location_id: serviceLocationId };
    this.captureEvent('Item Created', properties);
  }

  captureNonReportItemCreatedEvent(ossId: string, agent: string, serviceLocationId: number, disposition: string, activityId: number): void {
    const properties: Properties = {
      start_time: Date.now(),
      oss_id: ossId,
      agent,
      service_location_id: serviceLocationId,
      disposition,
      activity_id: activityId,
    };
    this.captureEvent('Non-Report Item Submitted', properties);
  }

  captureReportApprovedEvent(ossId: string, reviewer: string, serviceLocationId: number): void {
    const properties: Properties = { timestamp: Date.now(), oss_id: ossId, reviewer, service_location_id: serviceLocationId };
    this.captureEvent('Report Approved', properties);
  }

  captureReportRejectedEvent(ossId: string, reviewer: string, serviceLocationId: number): void {
    const properties: Properties = { timestamp: Date.now(), oss_id: ossId, reviewer, service_location_id: serviceLocationId };
    this.captureEvent('Report Rejected', properties);
  }

  private captureEvent(eventName: string, properties: Properties): void {
    posthog.capture(eventName, properties);
  }
}
